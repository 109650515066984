<template>
  <v-card outlined class="mb-5 pa-5">
    <slot></slot>
  </v-card>
</template>

<script>
/**
 * This component was made to reduce boilerplate (and have a consistent wrapper) for stepper content
 */
export default {
  name: "StepComponent"
};
</script>

<style scoped></style>
