<template>
  <v-autocomplete
    :items="filteredLinkedSamples"
    :filter="customFilter"
    hide-selected
    label="Linked Samples"
    multiple
    chips
    no-data-text="No samples to choose from"
    :item-text="item => `Reference ID #${item.id}`"
    item-value="id"
    v-model="linked_samples_proxy"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>Reference #: {{ item.id }}</v-list-item-title>
        <v-list-item-title v-if="item.farm"
          >Farm: {{ item.farm.title }}</v-list-item-title
        >
        <v-list-item-title v-else-if="item.source_other"
          >Farm Other: {{ item.source_other }}</v-list-item-title
        >
        <v-list-item-title v-if="item.description"
          >Description: {{ item.description }}</v-list-item-title
        >
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { validId } from "@/utils";

export default {
  name: "LinkedSamples",
  computed: {
    ...mapGetters("samples", ["sortedItems"]),
    ...mapFields("samples", [
      "active_sample.linked_samples",
      "active_sample.id"
    ]),
    samples() {
      return validId(this.id)
        ? this.sortedItems.filter(item => item.id !== this.id)
        : this.sortedItems;
    },
    linked_samples_proxy: {
      get() {
        return this.linked_samples.map(item => item.id);
      },
      set(newVal) {
        this.linked_samples = newVal.map(id => ({ id }));
      }
    },
    filteredLinkedSamples() {
      return this.samples.filter(sample =>
        sample.sample_status ? sample.sample_status.title !== "Complete" : false
      );
    }
  },
  methods: {
    customFilter(item, queryText) {
      const id = item.id;
      const title = item.farm
        ? item.farm.title.toLowerCase()
        : item.source_other
        ? item.source_other.toLowerCase()
        : "";
      const description = item.description
        ? item.description.toLowerCase()
        : "";
      const searchText = queryText.toLowerCase();
      const fullString = id + " " + title + " " + description;
      return fullString.indexOf(searchText) > -1;
    }
  }
};
</script>

<style scoped></style>
