<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <v-checkbox label="Best Mix?" v-model="is_best_mix"> </v-checkbox>
      </v-col>
    </v-row>
    <template v-if="is_best_mix">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="best_mix_farm_id"
            label="Best Mix Farm ID"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="best_mix_request_id"
            label="Best Mix Request ID"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "PackageBestMix",
  computed: {
    ...mapFields("samples", [
      "active_sample.is_best_mix",
      "active_sample.best_mix_farm_id",
      "active_sample.best_mix_request_id"
    ])
  }
};
</script>

<style scoped></style>
