<template>
  <v-card>
    <v-card-title>Add Farm</v-card-title>
    <v-container>
      <v-form ref="form" v-model="valid" lazy-validation>
        <FarmsEditFormFields></FarmsEditFormFields>
        <v-row dense class="pb-5">
          <v-btn @click="close()" color="secondary">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="save()" color="primary">
            Save
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import FarmsEditFormFields from "@/components/farms/FarmsEditFormFields";
import isEmpty from "lodash.isempty";

export default {
  name: "FarmAddDialog",
  components: { FarmsEditFormFields },
  props: {
    dialog: Boolean
  },
  data() {
    return {
      valid: false
    };
  },
  computed: {
    ...mapFields("samples", [
      "active_sample.farm_id",
      "active_sample.farms_source_id"
    ]),
    ...mapFields("farms", ["detailItem.client_id", "detailItem.lab_id"]),
    ...mapState("samples", ["active_sample"]),
    ...mapState("farms", ["detailItem"]),
    ...mapGetters("clients", ["selectedClient"])
  },
  methods: {
    ...mapMutations("farms", ["clearDetailItem", "setDetailItem"]),
    ...mapActions("farms", ["addFarm"]),
    ...mapActions("clients", ["getClients"]),
    save() {
      if (!this.$refs.form.validate()) return false;
      this.addFarm(this.detailItem).then(() => {
        this.farms_source_id = null;
        this.farm_id = this.detailItem.id;
        this.clearDetailItem();
        this.close();
      });
    },
    close() {
      this.$emit("update:dialog", false);
    },
    loadClientAndLab() {
      if (isEmpty(this.client_id) || isEmpty(this.lab_id)) {
        if (!isEmpty(this.selectedClient)) {
          this.client_id = this.selectedClient.id;
          this.lab_id = this.selectedClient.lab_id;
        } else {
          this.getClients().then(() => {
            this.client_id = this.selectedClient.id;
            this.lab_id = this.selectedClient.lab_id;
          });
        }
      }
    }
  },
  watch: {
    dialog(shown) {
      if (shown) {
        this.loadClientAndLab();
      } else {
        this.clearDetailItem();
      }
    }
  },
  mounted() {
    this.loadClientAndLab();
  }
};
</script>

<style scoped></style>
