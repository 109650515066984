<template>
  <CopyToSelectInput v-model="copy_to_proxy" @removed="removed" />
</template>

<script>
// import { mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import CopyToSelectInput from "@/components/copy_to/CopyToSelectInput";

export default {
  name: "CopyToSelect",
  components: {
    CopyToSelectInput
  },
  computed: {
    ...mapFields("samples", ["active_sample.copy_to"]),
    copy_to_proxy: {
      get() {
        return this.copy_to ? this.copy_to.map(item => item.id) : [];
      },
      set(newVal) {
        this.copy_to = [
          // Keep existing items as-is
          ...this.copy_to.filter(item => newVal.indexOf(item.id) > -1),
          // Add any new values
          ...newVal
            .filter(id => !this.copy_to.find(item => item.id === id))
            .map(id => ({ id }))
        ];
      }
    }
  },
  methods: {
    removed(val) {
      this.copy_to_proxy = this.copy_to_proxy.filter(item => item !== val.id);
    }
  }
};
</script>
