<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <v-checkbox label="Grand Valley?" v-model="is_grand_valley">
        </v-checkbox>
      </v-col>
    </v-row>
    <template v-if="is_grand_valley">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="grand_valley_file_number"
            label="File Number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="grand_valley_dairy_specialist"
            label="Dairy Specialist"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "PackageGrandValley",
  computed: {
    ...mapFields("samples", [
      "active_sample.is_grand_valley",
      "active_sample.grand_valley_file_number",
      "active_sample.grand_valley_dairy_specialist"
    ])
  }
};
</script>

<style scoped></style>
