<template>
  <v-autocomplete
    v-bind:value="value"
    v-on:input="updateValue($event)"
    :items="items"
    :search-input.sync="search"
    hide-selected
    label="Add Existing Report Recipient"
    multiple
    chips
    no-data-text="Report Recipient not found. Please use form below to create a new one"
    item-text="copy_to.email"
    item-value="copy_to_id"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click:close="remove(data.item.copy_to)"
      >
        {{ data.item.copy_to.email }}
      </v-chip>
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Report Recipient not found. Please use form below to create a new
            one.
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CopyToSelectInput",
  props: {
    value: {
      type: Array
    }
  },
  data() {
    return {
      search: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters("copyTo", ["items"])
  },
  methods: {
    ...mapActions("copyTo", ["getClientsCopyTos"]),
    updateValue(val) {
      this.$emit("input", val);
    },
    remove(item) {
      this.$emit("removed", item);
    }
  },
  watch: {
    search(val) {
      return val && val !== this.value && this.loading !== true;
    }
  },
  mounted() {
    this.loading = true;
    this.getClientsCopyTos()
      .then(() => {
        this.loading = false;
      })
      .catch(e => {
        console.error(e);
        this.loading = false;
        return false;
      });
  }
};
</script>
