<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <v-checkbox label="Calibrate?" v-model="is_calibrate"> </v-checkbox>
      </v-col>
    </v-row>
    <template v-if="is_calibrate">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field v-model="name" label="Name"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="herd" label="Herd"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="calibrate_number"
            label="Calibrate Number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="sample_calibrates_ingredients"
            label="Ingredient"
            item-text="title"
            item-value="id"
            no-data-text="Ingredients not available"
            v-model="sample_calibrates_ingredients_id"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="last_cut_days"
            label="Days since last cut"
            type="number"
            min="0"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" v-if="showCutting">
          <v-select
            :items="cuttings"
            no-data-text="Cutting options not available"
            label="Cutting"
            v-model="cutting"
          ></v-select>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";

export default {
  name: "PackageCalibrate",
  data() {
    return {
      loading: false,
      cuttings: Array.from({ length: 5 }, (x, i) => ++i)
    };
  },
  computed: {
    ...mapFields("samples", [
      "active_sample.is_calibrate",
      "active_sample.sample_calibrate.name",
      "active_sample.sample_calibrate.herd",
      "active_sample.sample_calibrate.calibrate_number",
      "active_sample.sample_calibrate.last_cut_days",
      "active_sample.sample_calibrate.cutting",
      "active_sample.sample_calibrate.sample_calibrates_ingredients_id"
    ]),
    ...mapGetters("baseData", ["sample_calibrates_ingredients"]),
    showCutting() {
      return (
        this.sample_calibrates_ingredients.find(
          item => item.id === this.sample_calibrates_ingredients_id
        ) || { title: "" }
      ).title
        .toLowerCase()
        .includes("alfalfa");
    }
  }
};
</script>

<style scoped></style>
