<template>
  <v-row dense>
    <v-col>
      <v-text-field
        label="Email"
        :rules="validationRules"
        v-model="invitee.email"
        :disabled="loading"
      >
        <template v-slot:append-outer>
          <v-icon v-if="!loading" @click="inviteCopyTo">
            mdi-plus
          </v-icon>
          <v-progress-circular
            v-else
            :size="30"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { getForm } from "@/utils";

export default {
  name: "CopyToInvite",
  props: ["inviteCompleteCb"],
  data() {
    return {
      validationRules: [
        this.email,
        v => !v || "Please click plus symbol to create the new report recipient"
      ],
      invitee: {
        email: ""
      },
      loading: false
    };
  },
  methods: {
    ...mapActions(["notify"]),
    ...mapActions("copyTo", ["invite"]),
    clearForm() {
      this.invitee.email = "";
    },
    // Validate email, option to return string or bool
    email(v, returnBool = false) {
      return (
        !v ||
        /.+@.+\..+/.test(v) ||
        (returnBool ? false : "Email must be valid")
      );
    },
    inviteCopyTo() {
      if (this.invitee.email === "") return false;
      // Requires full validation for parent form flow if submit hasn't occurred, but only email validation for invite
      if (!this.email(this.invitee.email, true)) return false;
      this.loading = true;
      this.invite(this.invitee.email).then(res => {
        if (typeof this.inviteCompleteCb === "function") {
          this.inviteCompleteCb(res);
        }
        const message = res
          ? `${this.invitee.email} Invited!`
          : "There was an error inviting this user";
        const attrs = res ? {} : { color: "error" };
        this.notify({ message, attrs });
        this.clearForm();
        getForm(this).resetValidation();
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped></style>
